export const releaseNotesDe: ReleaseNotes = [
    {
        release: "0.7.0",
        date: "2024-07-25",
        tags: [
            "Vorgänge", "Berechtigungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Berechtigungen ausgebaut: Sichtbarkeiten bei PDF-Export"
            },
            {
                "description": "Berechtigungen ausgebaut: Sichtbarkeit von Dokumenten"
            },
            {
                "description": "Vorgangsdetailseite im Verwaltungsbereich implementiert"
            },
        ],
        fixes: [
            {
                "description": "Fehlerbehebung beim Supporten von Benutzern (impersonate)"
            }
        ]
    },
    {
        release: "0.6.0",
        date: "2024-06-25",
        tags: [
            "Vorgänge", "Kategorien", "Berechtigungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Kategorien für Organisationen implementiert"
            },
            {
                "description": "Verknüpfung von Kategorien und Organisationen"
            },
            {
                "description": "Berechtigungen für Förderprogramme implementiert"
            },
            {
                "description": "Vorgangsübersicht implementiert"
            },
        ],
        fixes: [
            {
                "description": "Behebung Anzeigefehler JugendZählt"
            },
            {
                "description": "Fehlerbehebung beim Wechseln der aktiven Organisation"
            }
        ]
    },
    {
        release: "0.5.0",
        date: "2024-05-29",
        tags: [
            "Formulare", "Mobile Ansicht", "Rollen", "Jahre"
        ],
        type: "minor",
        notes: [
            {
                "description": "Programmnachweis im Antrag implementiert"
            },
            {
                "description": "Automatische Auswahl von Personen im Antrag implementiert"
            },
            {
                "description": "Jahresverwaltung konzeptioniert und implementiert"
            },
            {
                "description": "Rollenverwaltung konzeptioniert und implementiert"
            },
            {
                "description": "Zusatzfelder für Personendaten abhängig von Formular implementiert"
            },
            {
                "description": "Mobile Ansicht im Antrag"
            },
            {
                "description": "Fehlermeldungen im Antrag verbessert"
            },
            {
                "description": "Personenimport aus Excel oder amosWEB implementiert"
            },
        ],
        fixes: [
            {
                "description": "Fehlerhafte Adresssuche"
            },
            {
                "description": "Fehlerhafte Suche in Auswahlfeld"
            }
        ]
    },
    {
        release: "0.4.0",
        date: "2024-04-23",
        tags: [
            "Status", "Layouts", "Jugend Zählt"
        ],
        type: "minor",
        notes: [
            {
                "description": "Status Konzept ausgearbeitet und implementiert"
            },
            {
                "description": "Standard Bankverbindungen"
            },
            {
                "description": "Layout Vorgangsübersicht"
            },
            {
                "description": "Anpassung UX 'Event-Box' im Antrag"
            },
            {
                "description": "Jugend Zählt 2 Statistik"
            },
        ],
        fixes: [
            {
                "description": "Fehlerhafte Kommaprüfung"
            }
        ]
    },
    {
        release: "0.3.0",
        date: "2024-04-03",
        tags: [
            "Formulare", "Layouts", "Struktur"
        ],
        type: "minor",
        notes: [
            {
                "description": "Umbau Layout Formulare/Antragsübersicht"
            },
            {
                "description": "Neues Layout in Antragsseiten"
            },
            {
                "description": "Erweiterung der Maßnahmen/Teilnehmenden"
            },
            {
                "description": "Erweiterte Projektstruktur"
            },
            {
                "description": "Neue Seite für abgesendete Formulare"
            },
            {
                "description": "Weitere Formulare hinzugefügt"
            },
        ],
        fixes: [
            {
                "description": "Fehlerbehandlung in Adressauswahl"
            }
        ]
    },
    {
        release: "0.2.0",
        date: "2024-03-26",
        tags: [
            "Grundsystem", "Stammdaten", "Formulare"
        ],
        type: "minor",
        notes: [
            {
                "description": "Einstellungen zu Stammdaten (Förderprogramme, Formulare)"
            },
            {
                "description": "Separate Seiten/Layouts für Anträge"
            },
            {
                "description": "Neue Landingpage"
            },
            {
                "description": "Umsetzung erster Formulare"
            },
            {
                "description": "Implementierung der grundlegenden Prozesse"
            },
            {
                "description": "Bearbeitung von Maßnahmen und Personen"
            },
            {
                "description": "Erweitertes Organisationsmanagement"
            },
            {
                "description": "Adressdaten und -eingaben"
            }
        ]
    },
    {
        release: "0.1.0",
        date: "2023-10-19",
        tags: [
            "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Erste Fertigstellung des Grundsystems"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2023-09-01",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]